<template>
  <div class="menu">
    <van-grid :column-num="3">
      <van-grid-item v-for="(item,i) in menu"
                     :key="i"
                     :icon="'http://house.hscarstop.cn:98/'+item.MenuImg"
                     :url="item.LinkUrl"
                     :text="item.MenuName" />
    </van-grid>
  </div>
</template>

<script>
import { getMenuList } from '@/api/public'

export default {
  data () {
    return {
      formData: {
        DotId: '',
        SystemUserId: '',
      },
      menu: []
    }
  },
  created () {
    this.getMenuList();
  },
  methods: {
    getMenuList () {
      getMenuList().then((res) => {
        debugger;
        this.menu = res.data;
      }).catch(e => {
        console.log(e);
      })
    }
  }
}
</script>
<style lang="less">
.dot {
  .van-field__error-message {
    text-align: right;
  }
}
</style>


